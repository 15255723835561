<template>
	<div id="layout">
		<nav-bar />
		<aside-menu :menu="menu" v-if="theme === '0'" />
		<section>
			<router-view />
		</section>
		<!--<footer-bar />-->
	</div>
</template>

<script>
	import NavBar from "@/components/NavBar"
	import AsideMenu from "@/components/AsideMenu"
	import FooterBar from "@/components/FooterBar"

	export default {
		name: "Layout",
		components: {
			FooterBar,
			AsideMenu,
			NavBar,
		},
		computed: {
			menu() {
				return [
					this.hasPermission("Menu.General") ? this.$t('def.general') : "",
					[
						{
							href: "/",
							icon: "home",
							label: this.$t("menu.dashboard"),
							perm: "Menu.Dashboard",
						},
						{
							href: "/deposit",
							icon: "wallet-plus",
							label: this.$t("menu.deposits"),
							perm: "Menu.Deposit",
						},
						{
							href: "/withdraw",
							icon: "cash-multiple",
							label: this.$t("menu.withdrawals"),
							perm: "Menu.Withdraw",
						},
						{
							href: "/definition/bank-account",
							label: this.$t("menu.accounts"),
							perm: "Menu.BankAccount",
							icon: "bank",
						},
						{
							href: "/definition/setting",
							label: this.$t("menu.eftSettings"),
							icon: "cogs",
							perm: "Menu.Setting",
						},
						{
							href: "/definition/telegram-setting",
							label:this.$t("menu.telegramSettings"),
							icon: "telegram",
							perm: "Menu.TelegramSetting",
						},
						{
							href: "/definition/maldo-withdraw-panel/withdrawals",
							label: this.$t('menu.maldoWithdraw'),
							icon: "telegram",
							perm: "Menu.MaldoPanel",
						},
						{
							href: "/manual-transaction",
							label: this.$t("menu.manualBalance"),
							icon: "wallet-plus",
							perm: "Menu.ManualTransaction",
						},
						{
							href: "/cage-turnovers",
							label: this.$t("menu.cageTurnover"),
							icon: "wallet-plus",
							perm: "Menu.CageTurnover",
						},
					],

					this.hasPermission("Menu.ManualBankTransfer")
						? this.$t("def.eft")
						: "",
					[
						{
							href: "/bank-transfer-deposit",
							icon: "wallet-plus",
							label: this.$t("menu.deposits"),
							perm: "Menu.BankTransferDeposit",
						},
						{
							href: "/bank-transfer-withdraw",
							icon: "cash-multiple",
							label: this.$t("menu.withdrawals"),
							perm: "Menu.BankTransferWithdraw",
						},
						{
							href: "/bank-transfer-accounts",
							icon: "bank",
							label:this.$t("menu.accounts"),
							perm: "Menu.BankTransferAccounts",
						},
					],
					this.hasPermission("Menu.Report") ? this.$t("def.reports") : "",
					[
						{
							href: "/report/cage",
							icon: "chart-bar",
							label: this.$t("menu.cage"),
							perm: "Menu.CageReport",
						},
						{
							href: "/report/cage-v2",
							icon: "chart-bar",
							label: this.$t("menu.cagev2"),
							perm: "Menu.CageReport",
						},
						{
							href: "/report/yearly-deposit",
							icon: "chart-bar",
							label: this.$t("menu.yearlyDeposit"),
							perm: "Menu.YearlyDepositReport",
						},
						{
							href: "/report/yearly-withdraw",
							icon: "chart-bar",
							label: this.$t("menu.yearlyWithdraw"),
							perm: "Menu.YearlyWithdrawReport",
						},
						{
							href: "/report/failed-transaction-report",
							icon: "chart-bar",
							label: this.$t("menu.deposit"),
							perm: "Menu.FailedTransactionReport",
						},
						{
							href: "/report/daily-account",
							icon: "chart-bar",
							label: this.$t("menu.accounts"),
							perm: "Menu.DailyAccountReport",
						},
						{
							href: "/report/bank-transfer-group-report",
							icon: "chart-bar",
							label: this.$t("menu.eftGroup"),
							perm: "Menu.BankTransferGroupReport",
						},
						{
							href: "/report/investor",
							icon: "chart-bar",
							label: this.$t("menu.investment"),
							perm: "Menu.PlayerReport",
						},
					],
					this.hasPermission("Menu.DefinitionManagement")
						? this.$t("def.definitions")
						: "",
					[
						{
							href: "/definition/currencies",
							label: this.$t("menu.currencies"),
							perm: "Menu.Currency",
							icon: "cash-multiple",
						},
						{
							href: "/definition/payment-method",
							label:this.$t("menu.paymentMethods"),
							perm: "Menu.PaymentMethod",
							icon: "bank",
						},
						{
							href: "/definition/banklist",
							label:this.$t("menu.banks"),
							perm: "Menu.Bank",
							icon: "bank",
						},
						{
							href: "/definition/site",
							label: this.$t("menu.sites"),
							icon: "domain",
							perm: "Menu.Site",
						},
						{
							href: "/definition/blacklist",
							label: this.$t("menu.blacklist"),
							icon: "playlist-edit",
							perm: "Menu.Blacklist",
						},
						{
							href: "/investors",
							label: this.$t("menu.investments"),
							icon: "account-group",
							perm: "Menu.Investors",
						},
					],
					this.hasPermission("Menu.UserManagement")
						? this.$t("def.userManagement")
						: "",
					[
            {
              href: "/definition/online-users",
              icon: "account-group",
              label: this.$t("menu.onlineUsers"),
              perm: "Menu.OnlineUsers",
            },
            {
              href: "/definition/banned-list",
              icon: "account-group",
              label: this.$t('menu.bannedList'),
              perm: "Menu.BannedIP",
            },
						{
							href: "/definition/user",
							icon: "account",
							label: this.$t("menu.users"),
							perm: "Menu.User",
						},
						{
							href: "/definition/role",
							label:this.$t("menu.roles"),
							icon: "domain",
							perm: "Menu.Role",
						},
						{
							href: "/definition/permission",
							label: this.$t("menu.permissions"),
							icon: "domain",
							perm: "Menu.Permission",
						},
            {
              href: "/api-creator",
              label: this.$t("menu.apiCreator"),
              icon: "domain",
              perm: "Menu.ApiCreator",
            },
            {
              href: "/login-logs",
              label: this.$t("menu.loginLogs"),
              icon: "domain",
              perm: "Menu.Logins",
            },
					],
				]
			},
		}
	}
</script>
